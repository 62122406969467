import "intersection-observer"
import { PROD_SITE } from '@/const/prod.const';
import u from '@/util';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

let imgDomain = "";
const regex = /(http|https):\/\//;
if (process.env.NODE_ENV === 'production') {
    if(process.env.REACT_APP_ENV == 'test') {
        imgDomain = `https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/buildtest${PROD_SITE}/`
    } else {
        // imgDomain = `https://mj.pki.net.cn/build${PROD_SITE}/`
        imgDomain = `https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build${PROD_SITE}/`
    }
    // imgDomain = `https://mj.pki.net.cn/build${PROD_SITE}/`
    // imgDomain = `https://mj.pki.net.cn/buildtest${PROD_SITE}/`
} else {
    imgDomain = `https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build${PROD_SITE}/`
}

interface IImg {
    src: string;
    alt?: string;
    width?: number;
    height?: number;
    fill?: boolean;
    style?: CSSProperties,
    cls?: string;
    id?: string;
    ref?: any
    // 默认图
    errorImg?: 1 | 2 | 3,
    click?: () => void;
    errorCheck?: boolean;
    encryptUrl?: string;
    reload?: boolean; // 加密reload
    children?: any
}

export const appendHost = (url: string, withBuild = true) =>{
    if(!url) return "";

    if(url.includes('https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets')){
        return  `${url}.txt`;
    }
    if(url.includes('http') && !url.includes('https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets') && !url.includes('.txt') ){
        return  `${url}`;
    }

    if(!withBuild){
        // console.log(`withoutBuild loaded img - > ${imgDomain}${url}.txt`)
        return `${imgDomain.replace(`/build${PROD_SITE}`,``)}${url}.txt`;
    }
    // console.log(`withBuild loaded img - > ${imgDomain}${url}.txt`)
    return `${imgDomain}${url}.txt`;
}

const Img: FC<IImg> = ({ children, src, alt, width, height, fill, style, cls, id, errorImg, click, errorCheck, encryptUrl , reload}) => {
    const { ref, inView, entry } = useInView({
        rootMargin: '0px',
        threshold: 0.5,
    });
    const [error, setError] = useState<boolean>(false);
    const [imgSrc, setImgSrc] = useState<string>()

    useEffect(() => {
        if (src) {
            if(src.includes('http') && !src.includes('https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets') && !src.includes('.txt')  ){
                return setImgSrc(src);
            }

            let _src = src;
            const isBase64 = _src.indexOf("data:") >= 0;
            if (!isBase64 && !regex.test(_src) && src.includes('http')) {
                _src = `${imgDomain}${_src}`
                _src = _src.includes('.txt') ? _src : (_src+'.txt')
            }
            setImgSrc(_src);
        } else {
            const fetchData = async () => {
                const fetchAssetImg =  await u.fetchAssetImg(`${imgDomain}/images/default/loading/750x422.jpg.txt`);
                if(fetchAssetImg) return setImgSrc(fetchAssetImg);
            }
            fetchData()
        }
    }, [src])

    useEffect(() => {
        if (src) return;
        if (error) {
            let _v = "/images/default/loading/750x422.jpg"
            if (errorImg === 2) {
                _v = "/images/default/loading/790x530.jpg"
            }
            if((`${imgDomain}${_v}`).includes('.txt')){
                setImgSrc(`${imgDomain}${_v}`)
            }else{
                setImgSrc(`${imgDomain}${_v}.txt`)
            }
        }
    }, [errorImg, error, src])

    const errHandler = useCallback(() => {
        return setError(true)
    }, [])

    const decryptImg = useCallback( async () => {
        if (imgSrc && !imgSrc.includes("4AAQSkZJRgABAQAAAQABAAD")) return;

        // if(!`${imgSrc}`.includes("data") && `${src}`.includes('/images/')){
        //     src = `${imgDomain}${src}`;
        //     src = `${src}`.includes('.txt') ? src : `${src}.txt`;
        // }

        if(`${src}`.includes("jm.wuxingruoyin.top")) return src;
        const res = encryptUrl ? await u.fetchData(encryptUrl) : await u.fetchData(src);

        if(!`${src}`.includes('loading')){
            console.log(`decryptImg==========> ${encryptUrl} ${src}`  )
        }

        if (res) {
            if (typeof res === "string" && res.includes("404 Not Found")) {
                const fetchAssetImg =  await u.fetchAssetImg(`${imgDomain}/images/default/loading/750x422.jpg.txt`);
                if(fetchAssetImg) return setImgSrc(fetchAssetImg);
            }
            let __decrypted = ""
            try {
                __decrypted = res.indexOf("data") >= 0 ? res : u.imgDecrypt(res);
            } catch {
                const fetchAssetImg =  await u.fetchAssetImg(`${imgDomain}/images/default/loading/750x422.jpg.txt`);
                if(fetchAssetImg) return setImgSrc(fetchAssetImg);
            }

            return setImgSrc(__decrypted)
        } else {
            const fetchAssetImg =  await u.fetchAssetImg(`${imgDomain}/images/default/loading/750x422.jpg.txt`);
            if(fetchAssetImg) return setImgSrc(fetchAssetImg);
        }

    }, [encryptUrl, imgSrc])

    // const callDefaultImg = useCallback( async (url: any) => {
    //     if (url && url.indexOf("data") >= 0) return;
    //     const res = await u.fetchData(url);
    //     if (res) {
    //         let __decrypted = ""
    //         try {
    //             __decrypted = res.indexOf("data") >= 0 ? res : u.imgDecrypt(res);
    //         } catch {
    //         }

    //         if(__decrypted){
    //             return setImgSrc(__decrypted)
    //         }
    //     } 
    //     return;
    // }, [])

    useEffect(() => {
        if(`${src}`.includes('http') && !`${src}`.includes('https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets') && !`${src}`.includes('.txt') ){
            return setImgSrc(src);
        }

        if (`${imgSrc}`.includes("4AAQSkZJRgABAQAAAQABAAD") && inView) {
            decryptImg()
        }

        if((!`${imgSrc}`.includes("data")) && `${src}`.includes('/images/')){
            decryptImg()
        }
    }, [encryptUrl, inView, decryptImg, imgSrc, src])
    
    // useEffect(() => {
    //     if (reload && inView) {
    //         if (encryptUrl) {
    //             decryptImg()
    //         }
    //     }
    // }, [reload, inView,])

    useEffect(() => {
        if(`${src}`.includes('http') && !`${src}`.includes('https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets') && !`${src}`.includes('.txt') ){
            return setImgSrc(src);
        }
        if(`${src}`.includes("jm.wuxingruoyin.top")) return;

        if (`${src}`.indexOf("data") < 0) {
            if(!`${encryptUrl}`.includes('loading')){
                console.log('first load =================>', src , encryptUrl, imgSrc)
            }

            const fetchData = async () => {
                const fetchAssetImg =  await u.fetchAssetImg(`${imgDomain}/images/default/loading/750x422.jpg.txt`);
                if(fetchAssetImg) return setImgSrc(fetchAssetImg);
            }
            fetchData()
        }
    }, [encryptUrl, src])

    const actualImgEl = useMemo(() => {
        return <img ref={ref} onClick={click} id={id} onError={errHandler} style={style} sizes="100vh" className={`point fill ${cls}`} height={height || 0} width={width || 0} alt={alt || ''} src={imgSrc} />
    }, [ref, click, id, errHandler, style, cls, height, width, alt, imgSrc])

    return (
        <>
            {errorCheck ?
                error ? '' : actualImgEl
                : actualImgEl} 
            {children && children(imgSrc)}
        </>
    )
}

export default Img;