import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useUser from "@/hooks/useUser";
import { useNavigate } from "react-router-dom";
import Img, { appendHost } from "./img";
import useCategory from "@/hooks/useCategory";
import u, { ELocalKey, ProjectInfo, teseConstant } from "@/util";
import useConfBase from "@/hooks/useConfBase";
// import { cancelAllRequests } from "@/hooks/useAxios";
import styles from "./styles/category.module.css"
import useWindowDimensions from "@/hooks/useDimension";
import useAxios from "@/hooks/useAxios";
import { PROD_SITE } from "@/const/prod.const";
import { R } from "@/req";
import { postQesStats } from "@/apis/question";

interface ICategory {
    showSiteCili: boolean,
    showTopicOnly?: boolean,
}

const Category: FC<ICategory> = ({ showSiteCili, showTopicOnly }) => {
    const [userInfo] = useUser();
    const navigate = useNavigate;
    const category = useCategory()
    const [config, updateConfBase, getData] = useConfBase()
    const [caterList, setCaterList] = useState<any[]>([])
    const [defData, setDefData] = useState<any>([])
    const [zhutiData, setZhutiData] = useState<any>([])
    const { width } = useWindowDimensions();
    const { req } = useAxios("index/statistics", 'post', true);
    // const { req: req2 } = useAxios("index/countads", 'post', true);

    const goHitAllStats = useCallback(async (val: any, jumpUrl?: string) => {
        console.log(val)
        const __val = u.getStatsParams(false, val?.channel, val?.name, window.location.pathname,) as any;
        if (typeof val?.index === "number" && __val?.c) {
            __val["c"] = __val["c"] + val?.index + 1
        }
        req({ ...__val });
        // const token = u.getLocalData(ELocalKey.__QTKN, true);
        // if (!token) return;
        // const __tqknParams = {
        //     token,
        //     project_id: ProjectInfo.ID,
        //     site: __val.site,
        //     page: __val?.name,
        //     category: val?.channel,
        //     type: "link" as any,
        //     domain: __val.domain
        // }
        // R.request(postQesStats(__tqknParams), false, false, 1);
        if (jumpUrl) {
            return u.open(jumpUrl)
        }
    }, [])

    const handleAuthVIP = useCallback(async (val: any, type?: any) => {
        console.log('val', val)
        console.log('site_cili', config?.site_cili)

        if(val.channel === 'topic'){
            return goHitAllStats(val, val.url)
        }
        // xiaoshuo add novel.
        if(val.channel === 'xiaoshuo' && config?.site_xiaoshuo && config?.site_xiaoshuo === '1' && process.env.NODE_ENV === "production"){
            const val = "novel."
            let urlary = window.location.origin.split("https://") as any;
            if (urlary.length === 1) {
                urlary = window.location.origin.split("http://") as any;
            }
            if (urlary.length === 2) {
                urlary[0] = val;
            }
            urlary = urlary.join("").replace("www.", "");
            // const slash = urlary.lastIndexOf("/");
            // urlary = urlary.substring(0, slash);
            return u.open(`https://${urlary}`)
        }

        if(val.id === 149 || val.id === 158  || val.id === 159){
            return; // temp unable click
        }

        if(config?.site_cili == 1) {
            if(val.channel == 'cili') {
                cilihandler(0, val.name, val.channel);
                return;
            } else if(val.channel == 'meinv' || val.channel == 'tupian') {
                cilihandler(1, val.name, val.channel);
                return;
            }
        }
        // 概率跳转
        const isMatch = Math.floor(Math.random() * 4) === 3;
        const res = u.percentJump(val?.id);
        if (isMatch && res) {
            return goHitAllStats(val, res)
        }
        if (val?.exception) {
            // 根据拿到的id判断是否要跳到tese页面
            await goHitAllStats(val)
            if (typeof val?.actualId === 'number' && val?.actualId > 0) {
                return window.location.href = `/page/tese/${val?.actualId}` // u.open(`/page/tese/${val?.actualId}`)
            }
            return u.open(val?.url)
        }
        if (val?.channel && val?.id) {
            goHitAllStats(val)
            if (/^(http|https):\/\//.test(val?.url)) {
                sessionStorage.setItem("z", val?.name)
                sessionStorage.setItem("n", val?.url)
                return u.open("/external")
            }
            // cancelAllRequests()
            return u.mapCategoryRoute(navigate, type, val.channel, val.id, userInfo?.token)
        }
    }, [userInfo, navigate])


    useEffect(() => {
        const intervalQX = setInterval(qxData, 1000);

        function qxData() {
            const __data  = u.getStringtifyVal(localStorage.getItem(ELocalKey.__QX));
            console.log('qxData run this' , __data)
            if (Array.isArray(__data?.["zhuanti"])) {
                setZhutiData(__data["zhuanti"])
            }
            console.log('qxData' ,  zhutiData)
            if(__data){
                stopQX();
            }
        }

        function stopQX() {
        console.log('stopQX category', zhutiData)
        clearInterval(intervalQX);
        }
        // u.timeoutCb(() => {
        //     const __data  = u.getStringtifyVal(localStorage.getItem(ELocalKey.__QX));
        //     console.log('run this' , __data)
        //     if (Array.isArray(__data?.["zhuanti"])) {
        //         setZhutiData(__data["zhuanti"])
        //     }
        // });

        if(!localStorage.getItem(ELocalKey._LOCK_FORMAT) || localStorage.getItem(ELocalKey._FORMAT) === ''){
            // 在hooks里一直被使用，需要锁
        getData(4).then((res: any) => {
                                localStorage.setItem(ELocalKey._LOCK_FORMAT, '1'); 
                if (Array.isArray(res?.["tesezhuanqu"]?.[0]?.["child"])) {
                    setDefData(res["tesezhuanqu"][0]["child"])
                }

                if (Array.isArray(res?.["zhuanti"])) {
                    setZhutiData(res["zhuanti"])
                }
            })
        }else{
            if(localStorage.getItem(ELocalKey._FORMAT)){
                const data =JSON.parse(u.decrypt(localStorage.getItem(ELocalKey._FORMAT)));
                console.log('_FORMAT' , data)

                if (Array.isArray(data?.["tesezhuanqu"]?.[0]?.["child"])) {
                    setDefData(data["tesezhuanqu"][0]["child"])
                }

                try{
                    if (Array.isArray(data?.["zhuanti"])) {
                        setZhutiData(data["zhuanti"])
                    }
                }catch(err){}
            }
        }
    }, [config])

    const siteStyleCal = useMemo(() => {
        const __val = PROD_SITE as any;
        return __val === 3 ? styles.ci_orange : __val === 2 ? styles.ci_blue : styles.ci_original
    }, [])

    const siteStyleParentCal = useMemo(() => {
        const __val = PROD_SITE as any;
        return __val === 3 ? styles.mp_orange : __val === 2 ? styles.mp_blue : styles.mp_original
    }, [])

    useEffect(() => {
        if(defData && defData.length === 0){
            setDefData(teseConstant)
        }
        let _list = [...category];
        console.log(defData, 'defDatas')
        console.log(zhutiData, 'zhutiData')

        // def Data
        const __ext = defData.map((x: any) => ({
            ...x,
            name: x?.name,
            url: x.url,
            id: 1,
            channel: "tesezhuanqu",
            exception: true,
            actualId: x?.provider_id || 0
        }))
        const _val = { icon: categpryImg[1], name: "特色专区", data: __ext }

        // zhutiData
        const __ext2 = zhutiData.map((x: any) => ({
            ...x,
            name: x?.name,
            url: "/page/topic/" + x.id,
            id: x.id,
            channel: "topic",
            exception: true,
            actualId: x?.provider_id || 0
        }))
        const _val2 = { icon: categpryImg[0], name: "限时专题", data: __ext2, url: "/topic", channel: "topic" }
        console.log('zhaunti' , _val2);
        if (width < 600) {
            _list.push( _val)
            // _list.splice(1, 0, _val)
            _list.splice(1, 0, _val2)
            console.log('_list push cat 1' , _list);
        } else {
            // _list.splice(1, 0, _val)
            _list.push( _val)
            _list.splice(1, 0, _val2)
            console.log('_list push cat 2' , _list);
        }

        if (showSiteCili && u.isMobile()) {
            _list.splice(3, 0, "CILI")
        }

        if(showTopicOnly && _list[0].data.length > 0){
            _list = [_list[0]];
        }

        // if(window.location.href.indexOf("topic") >= 0 && !showTopicOnly && window.location.href.indexOf("video/topic") === -1){
        //     delete _list[0]; 
        // }

        return setCaterList(_list)
    }, [category, defData, zhutiData, width, showSiteCili])

    const cilihandler = useCallback(async (type: any, realName: any, channel: any) => {
        const __val = u.getStatsParams(false, "", "", window.location.pathname);
        // req({ ...__val, category: "site", name: type ? "pic" : "cili" });
        let categoryToSend = type ? channel : "cili"
        req({ ...__val, category: categoryToSend , name: realName});

        if (process.env.NODE_ENV === "production") {
            const val = type ? "pic." : "download."
            let urlary = window.location.origin.split("https://") as any;
            if (urlary.length === 1) {
                urlary = window.location.origin.split("http://") as any;
            }
            if (urlary.length === 2) {
                urlary[0] = val;
            }
            urlary = urlary.join("").replace("www.", "");
            // const slash = urlary.lastIndexOf("/");
            // urlary = urlary.substring(0, slash);
            return u.open(`https://${urlary}`)
        }
        return u.open(type ? config?.pic_url : config?.cili_url)
    }, [])

    const filterCater = useCallback((data: any[]) => {
        const normalHideIds = [66]
        const mustHideIds = [183]
        data = data?.filter((x) => !mustHideIds.includes(x.id))
        
        console.log(showTopicOnly,'cater', data)

        // if(showTopicOnly){
        //     return data;
        // }

        if (!showSiteCili) {
            console.log('not showSiteCili cater', data?.filter((x) => !normalHideIds.includes(x.id) && !normalHideIds.includes(x.parent_id)))
            return data?.filter((x) => !normalHideIds.includes(x.id) && !normalHideIds.includes(x.parent_id))
        };
        const showCiliHideIds = [140, 3, 4, 66];

        console.log('last filter cater', data?.filter((x) => !showCiliHideIds.includes(x.id) && !showCiliHideIds.includes(x.parent_id)))
        return data?.filter((x) => !showCiliHideIds.includes(x.id) && !showCiliHideIds.includes(x.parent_id))
    }, [showSiteCili])
    return (
        <div>
            {filterCater(caterList).map((x: any, i: number) => {
                const isTrue = x === "CILI";
                return <div key={i} className={`point grid ${styles.item} ${styles[i % 2 === 0 ? 'odd' : 'even']} ${isTrue && styles.h140}`}>
                    {isTrue ? <div className={styles.cili_wrapper}>
                        <img onClick={() => cilihandler(1, x.name, x.channel)} className={styles.site_cili_img} src={appendHost("/images/card1.png")} alt="" />
                        <img onClick={() => cilihandler(0, x.name, x.channel)} className={styles.site_cili_img} src={appendHost("/images/card2.png")} alt="" />
                    </div> : <>
                        <div onClick={() => handleAuthVIP({ ...x, index: -1 }, 1)}>
                            <div className={styles.c_m_left}>
                            {x.name === '在线电影' && <>
                                        <Img width={40} height={31} src={categpryImg[5]} />
                                        </>}
                                        {x.name === '情色小说' && <>
                                        <Img width={40} height={31} src={categpryImg[6]} />
                                        </>}
                                        {x.name === '有声小说' && <>
                                        <Img width={40} height={31} src={categpryImg[7]} />
                                        </>}
                                        {x.name === '撸撸图区' && <>
                                        <Img width={40} height={31} src={categpryImg[1]} />
                                        </>}
                                        {x.name === '激情图区' && <>
                                        <Img width={40} height={31} src={categpryImg[4]} />
                                        </>}
                                        {x.name === '手机下载' && <>
                                        <Img width={40} height={31} src={categpryImg[3]} />
                                        </>}
                                        {x.name === '站站推荐' && <>
                                            <Img width={40} height={31} src={categpryImg[8]} />
                                        </>}
                                        {x.name === '另类色情' && <>
                                        <Img width={40} height={31} src={categpryImg[9]} />
                                        </>}
                                        {(i < 12 && x.name !== '站站推荐' && x.name !== '另类色情' && x.name !== '手机下载'
                                            && x.name !== '在线电影'  && x.name !== '情色小说'  && x.name !== '有声小说' 
                                            && x.name !== '撸撸图区' && x.name !== '激情图区'
                                        ) && <>
                                            <Img width={40} height={31} src={categpryImg[i]} />
                                        </>}
                                {i >= 12 && <>
                                    <Img width={40} height={31} src={categpryImg[1]} />
                                </>}
                                <div>{x.name}</div>
                            </div>
                            <div className={`${styles.menu_parent} ${siteStyleParentCal} text_center`}>
                                {x.name}
                            </div>
                        </div>
                        <div className={`fl align_center`}>
                            {Array.isArray(x.data) && x.data.map((x: any, v: number) => {
                                return <div key={v} className="fl1 point relative">
                                    {!!x.tags && <div className={`${styles.cater_label}`}>{x.tags}</div>}
                                    {/* {v === 1 &&  <div className={`${styles.cater_label}`}>最新</div>}
                                    {v === 6 &&  <div className={`${styles.cater_label}`}>热销</div>} */}
                                    <div onClick={() => handleAuthVIP({ ...x, index: v })} className={`${styles.cater_item} ${siteStyleCal}`}>{x.name}</div>
                                </div>
                            })}
                        </div>
                    </>}
                </div>
            })}
        </div>
    )
}
export default Category;

export const categoryMap = {
    "cili/140": 3,
    "tupian/3": 3,
    "meinv/4": 3,
    "xiaoshuo/5": 3,
    "yousheng/6": 3,
    "find/66": 3,
}

export const categpryImg = [
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_vip%20area.png.txt",
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_tesezhuanqu.png.txt",
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_Movie.png.txt",
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_app.png.txt", //3
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_Photograph.png.txt", //4
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_picture.png.txt", //5
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_xiaoshuopng.png.txt",
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_youshengxiaospng.png.txt",
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_Movie2.png.txt",
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_Movie3.png.txt",
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_tesezhuanqu.png.txt",
    "https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1//images/menu/home_icon_tesezhuanqu.png.txt",
]